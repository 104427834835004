import React from 'react';
import { ServerStatus } from './serverStatus';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <ServerStatus />
      </header>
    </div>
  );
}

export default App;
