import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

export const ServerStatus = () => {
  const [text, setText] = useState();

  useEffect(() => {
    async function textChange() {
      const response = await fetch('/app/express_backend');
      const body = await response.json();

      if (response.status !== 200) {
        throw Error(body.message);
      }
      console.log('body', body);
      setText(body.express);
    }

    textChange();
  }, []);

  return <Button>{text}</Button>;
};
